import './servicos.css'
import { GiWireframeGlobe, GiCctvCamera, GiPhone } from 'react-icons/gi'
import { FiRss } from 'react-icons/fi'
import { useEffect } from 'react'

export default function Servicos(){

    function ShowServicos(){

        const serviceSection = document.getElementById('services')

        Array.from(document.getElementsByClassName('service-info')).forEach((item, index) => {
        
            if(document.getElementById('servicos').getBoundingClientRect().top < window.innerHeight){
                item.classList.add('show')
                serviceSection.classList.add('service-show')
            }
        
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", ShowServicos);
        return () => window.removeEventListener("scroll", ShowServicos)
    }, [])

    return (
        <>
        <div id="servicos" className="servicos">
            <div id="services" className="services">

                    <div className="service-info">

                        <div className="service-icon">
                            <FiRss />
                        </div>

                        <h2>Serviços de Internet</h2>


                        <div className="service-text">
                        <p>
                            Nossa internet suporta um grande tráfego de informações e permite a 
                            execução com alto desempenho de conteúdos audiovisuais online, 
                            videoconferências e etc.
                        </p>
                        </div>

                    </div>
                    
                    <div className="service-info">

                        <div className="service-icon"><GiWireframeGlobe /></div>

                        <h2>Arquitetura de Redes</h2>

                        <div className="service-text">
                        <p>
                            Nossa equipe dispõe de profissionais especializados, com muitos anos de experiência
                            na área de redes. Prestamos serviços desde a implementação até
                            mesmo à refatoração de toda uma arquitetura.
                        </p>
                        </div>

                    </div>

                    <div className="service-info">

                        <div className="service-icon"><GiPhone /></div>

                        <h2>Telefonia VoIP</h2>

                        <div className="service-text">
                        <p>
                            A telefonia VoIP vem ganhando cada vez mais espaço nos dias de hoje e, para tanto,
                            contamos com uma equipe exclusiva para lidar com todo o tratamento de telefonia VoIP.

                        </p>
                        </div>

                    </div>

                    <div className="service-info">

                        <div className="service-icon"><GiCctvCamera /></div>

                        <h2>Sistema CFTV</h2>

                        <div className="service-text">
                        <p>
                            CFTV's são sistemas de distribuição de imagens de câmeras para pontos de visualização. 
                            A R&L não só faz a instalação como também dá suporte e manutenção para tais sistemas. 
                        </p>
                        </div>
                        
                    </div>
    </div>
        </div> </>
    )
}