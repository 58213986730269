import './home.css'
import Header from '../../components/header'
import Servicos from "../../components/Servicos"
import Satisfeitos from '../../components/Satisfeitos'
import Sobre from '../../components/Sobre'
import Clientes from '../../components/Clientes'
import Contato from '../../components/Contato'
import Footer from '../../components/footer'


export default function Home(){

    return (
        <>
        
        <main>
        <div id="home">
            <div className="home-container">

            <Header />
                <div className="hero">
                    <h2>Construindo Conexões</h2>
                    <h1>Você<br/>Mais Conectado</h1>

                        <button type="button"><a href="#servicos">Dê uma olhada</a></button>
                        
                </div>

            </div>
        </div>
        
        <Servicos />
        <Satisfeitos />
        <Sobre />
        <Clientes />
        <Contato />
        <Footer />
        </main>
        </>
    )
}