import './404.css'
import { RiEmotionSadLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

export default function NotFound(){
    return(
        <div className="notFounded-div">

            <div className="notFounded-text">
                
            <RiEmotionSadLine />
                <h1>404</h1>
                <h3>Ops, página não encontrada</h3>
                <p>Infelizmente, não conseguimos encontrar a página que você está procurando.
                    Isso pode significar que o link que você acessou não é mais válido, ou que
                    esse endereço não existe mais.
                </p>
                <Link to="/">Clique aqui pra voltar à página inicial</Link>
            </div>
        </div>
    )
}