import Header from "../../components/header"
import Contato from '../../components/Contato'
import Footer from '../../components/footer'

export default function Eventos(){
    return (
        <>
        <Header/>

        <Contato/>

        <Footer/>
        </>
    )
}