import { Formik, useField, Form } from 'formik'
import { gql, useMutation } from '@apollo/client'

import './signup.css'

const CADASTRAR = gql`
    mutation cadastrar(
        $email: String!,
        $username: String!,
        $password1: String!,
        $password2: String!
    ){
        register(
            email: $email,
            username: $username,
            password1: $password1,
            password2: $password2
        ){
            success,
            errors,
        }
    }
`

const Campo = ({ label, ...props}) => {
    const [field, meta] = useField(props)

    return (
        <>
        {meta.error && meta.touched ? (
            <div className="invalid">{meta.error}</div>
        ) : null }
        <div className="form-group">

            <input 
            {...field} 
            {...props}
            className={meta.error && meta.touched ? 'is-invalid' : null }/>
            
        </div>
        </>
    )
}

export default function Registrar({ hide }){

    const [novoCadastro, {loading, data}] = useMutation(CADASTRAR)

    if(loading){
        return(
            <div>
                Loading...
            </div>
        )
    }

    if(data){
        return(
            <>
            <div className="signup-form">
                <h2>Por favor, verifique seu e-mail para confirmar o cadastro.</h2>
            </div>
            </>
        )
    }

    return (
            <Formik
            initialValues={ { email: '', password1: '', password2: '' }}
            enableReinitialize
            onSubmit={(values, actions) => {
                values.username = values.email
                novoCadastro({variables: values})
                .then(data => {
                    if(data.data.register.success === true){
                        alert('Por favor verifique seu e-mail para validar a conta.')
                    }
                    else{
                        alert('Algo deu errado. Não utilize senhas muito parecidas com seu email ou nome de usuário.')
                    }
                })
            }}
            validate={(values) => {
                const errors = {};
                if(!values.email){
                    errors.email = '* Este campo é obrigatório';
                }
                if(!values.password1){
                    errors.password1 = '* Este campo é obrigatório';
                }
                if(values.password1.length < 8){
                    errors.password1 = '* Deve ter pelo menos 8 caracteres';
                }
                if(!values.password2){
                    errors.password2 = '* Este campo é obrigatório';
                }
                if(values.password1 !== values.password2){
                    errors.password1 = 'Confirmação incorreta';
                    errors.password2 = 'Confirmação incorreta';
                }
                return errors;
            }}
            
            >

                {( values ) => (

                    <Form autoComplete="off" className={`signup-form`}>

                        <h3>Cadastrar</h3>

                        <Campo name="email" type="email" placeholder="E-mail" required/>

                        <Campo name="password1" type="password" placeholder="Senha" required/>

                        <Campo name="password2" type="password" placeholder="Confirmar Senha" required/>

                        <div>
                            <button  type="button" onClick={() => {hide();}}>Cancelar</button>
                            <button className="signup-form-save" type="submit">Salvar</button>
                        </div>                

                    </Form>

                )}

            </Formik>
    )
}