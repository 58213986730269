import { Switch } from 'react-router-dom'
import Route from './Route'


import Home from "../pages/Home";
import Eventos from '../pages/Eventos';
import Perfil from '../pages/Perfil';
import Contrato from '../pages/Contrato';

import Active from '../pages/AtivarConta';
import ResetPassword from '../pages/EsqueciSenha';

import NotFound from '../pages/404'


export default function Routes(){
    return (
        <Switch>
                
                <Route exact path="/eventos" component={Eventos} isPrivate />

                <Route exact path="/perfil" component={Perfil} isPrivate />

                <Route exact path="/contrato" component={Contrato} isPrivate />

                <Route exact path="/activate/:token" component={Active} />

                <Route exact path="/password-reset/:token" component={ResetPassword} />

                <Route exact path="/" component={Home} />

                <Route component={NotFound} />

        </Switch>
    )
}