import { Formik, useField, Form } from 'formik'
import { gql, useMutation } from '@apollo/client'

import './signup.css'

const ESQUECI_SENHA = gql`
    mutation esqueciSenha(
        $email: String!,
    ){
        sendPasswordResetEmail(
            email: $email,
        ){
            success,
            errors,
        }
    }
`

const Campo = ({ label, ...props}) => {
    const [field, meta] = useField(props)

    return (
        <>
        {meta.error && meta.touched ? (
            <div className="invalid">{meta.error}</div>
        ) : null }
        <div className="form-group">

            <input 
            {...field} 
            {...props}
            className={meta.error && meta.touched ? 'is-invalid' : null }/>
            
        </div>
        </>
    )
}

export default function EsqueciSenha({ hide }){

    const [esqueci, {loading, error}] = useMutation(ESQUECI_SENHA)

    if(loading){
        return(
            <div className="signup-form">
                <h3>Loading...</h3>
            </div>
        )
    }

    if(error){
        return(
            <div className="signup-form">
                <h3>Erro: {error.message}</h3>
            </div>
        )
    }

    return (
            <Formik
            initialValues={ { email: ''}}
            onSubmit={(values, actions) => {
                esqueci({variables: values})
                .then(data => {
                    if(data.data.sendPasswordResetEmail.success === true){
                        alert('Verifique seu e-mail')
                        hide()
                    }
                    else{
                        alert('Algo deu errado. Verifique se seu login está correto.')
                    }
                })
            }}
            validate={(values) => {
                const errors = {};
                if(!values.email){
                    errors.email = '* Este campo é obrigatório';
                }
                return errors;
            }}
            
            >

                {( values ) => (

                    <Form autoComplete="off" className={`signup-form`}>

                        <h3>Esqueceu a senha?</h3>

                        <Campo name="email" type="email" placeholder="E-mail" required/>

                        <div>
                            <button  type="button" onClick={() => {hide();}}>Cancelar</button>
                            <button className="signup-form-save" type="submit">Enviar</button>
                        </div>                

                    </Form>

                )}

            </Formik>
    )
}