import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import AuthProvider from './contexts/auth';

import { ApolloProvider } from '@apollo/client'
import { client } from './contexts/apolloHandle'


function App() {

    return (
      <ApolloProvider client={client} >
      <AuthProvider>
  
        <Router basename="/">
  
          <Routes/>
  
        </Router>
        
      </AuthProvider>
      </ApolloProvider>
    );
  
}


export default App;
