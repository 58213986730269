import './clientes.scss'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { gql, useQuery } from '@apollo/client'

const CLIENTES = gql`
    query allClientes {
    allClientes {
            id,
            nome,
            imagem
        }
    }
    `

export default function Clientes(){

    
    const { loading, data } = useQuery(CLIENTES)

    var settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        autoplaySpeed: 0,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        cssEase: "linear",
        swipeToSlide: true,
        arrows: false,
        initialSlide: 0,
        responsive: [
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
          ]
    }

    return(
        <div id="section-clientes">
            <div className="section-clientes-glass">
                <h3>Clientes</h3>
                <h1>Alguns de Nossos Clientes</h1>

                {loading? <div className="clientes-body" style={{width: 100}}>loading...</div> : null}

                {data? 
                <Slider {...settings} >
                    {data.allClientes.map(({id, nome, imagem},) => (
                        <div className="cliente-picture" key={id}>
                            <img src={`/media/${imagem}`} alt={nome}/>
                       </div>
                    ))}
                </Slider> : null   
                }
                
            </div>
        </div>
    )
}