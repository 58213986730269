import Header from '../../components/header'
import Footer from '../../components/footer'

import './contrato.css'

import contrato from '../../assets/contrato.pdf'

export default function Contrato(){

    return (
        <>
        <div className="header-color">
            <div className="header-glass">
            <Header />
            </div>
        </div>

        <div className="contrato-section">
            <iframe src={contrato} title="Contrato">
            </iframe>
        </div>
        
        <Footer />
        </>
    )
}