import './header.scss'
import logo from '../../assets/logo_branca.png'

import { NavLink, Link } from 'react-router-dom'

import { GrFacebookOption } from 'react-icons/gr'
import { FiInstagram, FiYoutube, FiSend, FiChevronDown, FiLinkedin } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'

import Registrar from '../../pages/Registrar'
import Login from '../../pages/Registrar/login'
import EsqueciSenha from '../../pages/Registrar/esqueciSenha'

import { useContext, useState } from 'react'

import { AuthContext } from '../../contexts/auth'


export default function Header(){

    const [register, setRegister] = useState('hidden')
    const [login, setLogin] = useState('hidden')
    const [esqueci, setEsqueci] = useState('hidden')
    const [dropdown, setDropdown] = useState('hidden')

    const { user, LogOut } = useContext(AuthContext)

    const abreRegister = () => {
        if(register === 'hidden'){
            setRegister('registerShow')
        }
        if(register === 'hidden' && login !== 'hidden'){
            setLogin('hidden')
            setRegister('registerShow')
        }
        if(register !== 'hidden'){
            setRegister('hidden')
        }
        if(esqueci !== 'hidden'){
            setEsqueci('hidden')
        }
    }

    const abreLogin = () => {
        if(login === 'hidden' && register === 'hidden'){
            setLogin('registerShow')
        }
        if(login === 'hidden' && register !== 'hidden'){
            setRegister('hidden')
            setLogin('registerShow')
        }
        if(login !== 'hidden'){
            setLogin('hidden')
        }
    }

    const abreEsqueci = () => {
        if(esqueci === 'hidden'){
            setLogin('hidden')
            setEsqueci('registerShow')
        }
        if(esqueci !== 'hidden'){
            setLogin('registerShow')
            setEsqueci('hidden')
        }

    }
 
    const abreDropDown = () => {
        if(dropdown === 'hidden'){
            setDropdown('')
        }
        else{
            setDropdown('hidden')
        }
    }

    return (
        <>
        <header className="header">


                <div className="menu-social">
                
                    <a href="https://www.facebook.com/RL-Connect-Telecomunica%C3%A7%C3%B5es-LTDA-1813139105618731/?ref=aymt_homepage_panel" target="blank">
                        <GrFacebookOption/>
                    </a>

                    <a href="https://www.instagram.com/rlconnect/?hl=pt-br" target="blank">
                        <FiInstagram/>
                    </a>

                    <a href="https://www.youtube.com/channel/UCxeRNq3mw9OszC0rvFX3KtQ/featured" target="blank">
                        <FiYoutube/>
                    </a>

                    <a href="https://www.linkedin.com/in/rl-connect-telecomunica%C3%A7%C3%A3o-a7b000151/" target="blank">
                        <FiLinkedin/>
                    </a>

                    <a href="https://api.whatsapp.com/send?phone=5521969452915" target="blank">
                        <FaWhatsapp/>
                    </a>

                </div>

                <div className="options-right">
                    <p><FiSend /> comercial@rlconect.com.br</p>
                    <p><FaWhatsapp /> 21 96945-2915</p>
                </div>
            
                <div className="options-login profile-settings">
                    {user? <p onClick={abreDropDown}>{user} <FiChevronDown /></p> 
                    :
                    <>
                    <button onClick={abreLogin} type="button">Login</button>
                    
                    <button onClick={abreRegister} type="button">Registrar</button>
                    </>
                    }

                    {user?
                    <div className={`dropdown-content ${dropdown}`}>
                        <Link to="/contrato">Contrato</Link>
                        <p onClick={LogOut} >Sair</p>
                    </div>
                    :
                    null
                    }
                </div>

                <div className={register}>
                <Registrar hide={abreRegister}/>
                </div>

                <div className={login}>
                    <Login hide={abreLogin} forget={abreEsqueci}/>
                </div>
            
                <div className={esqueci}>
                    <EsqueciSenha hide={abreEsqueci}/>
                </div>

        </header>

        <nav className="nav-menu">

            <div className="nav-logo">
                <Link to="/">
                    <img src={logo} alt="logo" draggable={false}/>
                </Link>
            </div>

            <ul>
                <NavLink exact to="/">Home</NavLink>
                <a href="https://rlconect.mikweb.com.br">Central do Cliente</a>
            </ul>
        </nav>
        </>
    )
}