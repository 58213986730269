import { Formik, useField, Form } from 'formik'
import { gql, useMutation } from '@apollo/client'
import { useContext } from 'react'

import { AuthContext } from '../../contexts/auth'

import './signup.css'

const LOGAR = gql`
    mutation logar(
        $email: String,
        $password: String!,
    ){
        tokenAuth(
            email: $email,
            password: $password,
        ){
            success,
            errors,
            refreshToken,
            token,
            user{
                email
            }
        }
    }
`

const RESEND_EMAIL_ACT = gql`
    mutation resendActivationEmail(
        $email: String!,
    ){
        resendActivationEmail(email: $email){
            success,
            errors,
        }
    }
`

const Campo = ({ label, ...props}) => {
    const [field, meta] = useField(props)

    return (
        <>
        {meta.error && meta.touched ? (
            <div className="invalid">{meta.error}</div>
        ) : null }
        <div className="form-group">

            <input 
            {...field} 
            {...props}
            className={meta.error && meta.touched ? 'is-invalid' : null }/>
            
        </div>
        </>
    )
}

export default function Login({ hide, forget }){

    const [logar, {loading}] = useMutation(LOGAR)

    const [actEmail] = useMutation(RESEND_EMAIL_ACT)

    const { setUser } = useContext(AuthContext)

    if(loading){
        return(
            <div className="signup-form">
                <h3>Loading...</h3>
            </div>
        )
    }


    return (
            <Formik
            initialValues={ { email: '', password: '' }}
            onSubmit={(values, actions, ) => {
                logar({variables: values})
                .then(data => {
                    if(data.data.tokenAuth.success === true){
                        const token = data.data.tokenAuth.token
                        localStorage.setItem('JWT', token)
                        localStorage.setItem('user', data.data.tokenAuth.user.email)
                    
                        setUser(data.data.tokenAuth.user.email)
                        hide()
                        
                    }
                    if(data.data.tokenAuth.errors){
                        if(data.data.tokenAuth.errors.nonFieldErrors[0].code === "not_verified"){
                            alert('Por favor verifique seu email para validar a conta')
                            actEmail({variables: {email: values.email}})
                        }
                    }
                    if(data.data.tokenAuth.success === false){
                        alert('Algo deu errado. Verifique se seu login está correto.')
                    }
                })
            }}
            validate={(values) => {
                const errors = {};
                if(!values.email){
                    errors.email = '* Este campo é obrigatório';
                }
                if(!values.password){
                    errors.password = '* Este campo é obrigatório';
                }
                return errors;
            }}
            
            >

                {( values ) => (

                    <Form autoComplete="off" className={`signup-form`}>

                        <h3>Login</h3>

                        <Campo name="email" type="email" placeholder="E-mail" required/>

                        <Campo name="password" type="password" placeholder="Senha" required/>

                        <small onClick={forget}>Esqueci minha senha</small>

                        <div>
                            <button  type="button" onClick={() => {hide();}}>Cancelar</button>
                            <button className="signup-form-save" type="submit">Entrar</button>
                        </div>                

                    </Form>

                )}

            </Formik>
    )
}