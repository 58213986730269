import Header from '../../components/header';
import Footer from '../../components/footer';

import './esqueciSenha.css';

import { useParams } from 'react-router-dom';

import logo from '../../assets/logo_branca.png';

import {FiX, FiCheck} from 'react-icons/fi';

import { gql, useMutation } from '@apollo/client';

import { Formik, useField, Form } from 'formik';


const RESETAR_SENHA = gql`
    mutation passwordReset(
        $token: String!,
        $newPassword1: String!,
        $newPassword2: String!,
    ){
        passwordReset(
            token: $token,
            newPassword1: $newPassword1,
            newPassword2: $newPassword2,
        ){
            success,
            errors,
        }
    }
`


const Campo = ({ label, ...props}) => {
    const [field, meta] = useField(props)

    return (
        <>
        {meta.error && meta.touched ? (
            <div className="invalid">{meta.error}</div>
        ) : null }
        <div className="form-group">

            <input 
            {...field} 
            {...props}
            className={meta.error && meta.touched ? 'is-invalid' : null }/>
            
        </div>
        </>
    )
};

export default function ResetPassword(){
    
    const { token } = useParams()

    const [resetarSenha, {loading, error, data}] = useMutation(RESETAR_SENHA)


    return (
        <>
        <div className="home">
            <div className="home-container">

                <Header />

                    
            <div className="password-reset-section">
            
                {data? 

                <div className={data? data.passwordReset.success? 'success logo-area' : 'error logo-area'
                        : `logo-area` }>
                        {loading? 
                        <img src={logo} alt="logo"/>
                        :
                        null
                        }
                        {
                            error? `${error.message}` : null
                        }
                        {
                            data? data.passwordReset.success? <FiCheck /> : <FiX /> 
                            : null
                        }
                </div>
                : null
                }

                {data? null : 
                
                    <Formik
                        initialValues={ { newPassword1: '', newPassword2: '' }}
                        enableReinitialize
                        onSubmit={(values, actions) => {
                            values.token = token
                            resetarSenha({variables: values})
                            .then(data => {
                                if(data.data.passwordReset.success === true){
                                    actions.resetForm()
                                }
                                else{
                                    alert('Algo deu errado. Não utilize senhas muito parecidas com seu email ou nome de usuário.')
                                }
                            })
                        }}
                        validate={(values) => {
                            const errors = {};
                            if(!values.newPassword1){
                                errors.password1 = '* Este campo é obrigatório';
                            }
                            if(values.newPassword1.length < 8){
                                errors.password1 = '* Deve ter pelo menos 8 caracteres';
                            }
                            if(!values.newPassword2){
                                errors.password2 = '* Este campo é obrigatório';
                            }
                            if(values.newPassword1 !== values.newPassword2){
                                errors.newPassword1 = 'Confirmação incorreta';
                                errors.newPassword2 = 'Confirmação incorreta';
                            }
                            return errors;
                        }}
                        
                        >

                            {( values ) => (

                                <Form autoComplete="off" >

                                    <h3>Alterar Senha</h3>

                                    <Campo name="newPassword1" type="password" placeholder="Senha" required/>

                                    <Campo name="newPassword2" type="password" placeholder="Confirmar Senha" required/>

                                    <div>
                                        <button className="signup-form-save" type="submit">Salvar</button>
                                    </div>                

                                </Form>

                            )}

                    </Formik>
               
                } 
            </div>
        

            </div>
        </div>

        <Footer />
        </>
    )
};