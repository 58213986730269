import './sobre.css'
import wp from '../../assets/wp3.jpg'
import { useEffect } from 'react'

export default function Sobre(){

    function ShowServicos(){

        Array.from(document.getElementsByClassName('quem-somos-text')).forEach((item, index) => {
        
            if(document.getElementById('quem-somos').getBoundingClientRect().top < window.innerHeight){
                item.classList.add('show')
            }
        
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", ShowServicos);
        return () => window.removeEventListener("scroll", ShowServicos)
    }, [])

    return (
        <>
        <div id="quem-somos" className="quem-somos">
            
            <div className="quem-somos-body">
                <div className="image-div">
                <img src={wp} alt="sobre" />
                </div>

                <div className="quem-somos-text">
                <h3>Um pouco sobre</h3>
                <h1>Bem-vindo à R&L Connect</h1>
                <p>
                A R&L Connect está no mercado há vários anos, a nossa missão é atender nossos clientes com qualidade otimizada visando a total satisfação de nossos clientes e parceiros.
                </p>
                <p>
                Nossa infraestrutura está preparada para disponibilizar o que há de melhor em link de dados profissional, trabalhamos com os melhores profissionais do mercado e nossa equipe está sempre se capacitando para que possamos oferecer o que há de melhor em serviço e suporte.
                </p>
                <p>
                Nossa estrutura contempla geradores, nobreak, fibra ótica, rádio e back-up. Tudo para garantir a segurança que sua empresa precisa.
                </p>
                <p>
                Faça parte você também do nosso time de parceiros! 
                </p>
                </div>
            </div>
            
        </div>
        </>
    )
}