import './perfil.css'
import Header from '../../components/header'
import Footer from '../../components/footer'

export default function Perfil(){

    return (
        <>
        <div className="header-color">
            <div className="header-glass">
            <Header />
            </div>
        </div>

        <div className="profile-section">
            Alguma coisa
        </div>
        
        <Footer />
        </>
    )
}