import Header from '../../components/header'
import Footer from '../../components/footer';

import './ativarConta.css';
import logo from '../../assets/logo_branca.png';

import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import {FiX, FiCheck} from 'react-icons/fi';

import { gql, useMutation } from '@apollo/client';


const VERIFY_ACCOUNT = gql`
    mutation verificarConta(
        $token: String!,
    ){
        verifyAccount(
            token: $token,
        ){
            success,
            errors,
        }
    }
`

export default function Active(){
    
    const { token } = useParams()

    const [verificar, {loading, error, data}] = useMutation(VERIFY_ACCOUNT)

    useEffect(() => {
        verificar({variables: {token: token}})
    }, [token, verificar])

    return (
        <>
        <div className="home">
            <div className="home-container">

                <Header />

                    
            <div className="activate-section">
                <div className={data? data.verifyAccount.success? 'success logo-area' : 'error logo-area'
                    : `logo-area` }>
                    {loading? 
                    <img src={logo} alt="logo"/>
                    :
                    null
                    }
                    {
                        error? `${error.message}` : null
                    }
                    {
                        data? data.verifyAccount.success? <FiCheck /> : <FiX /> 
                        : null
                    }
                </div>

                {data? data.verifyAccount.success? <>
                <h3>Tudo certo!</h3>
                <p>Sua conta foi verificada com sucesso.</p>
                </> : 
                <>
                <h3>Parece que houve algum engano...</h3>
                <p>Ocorreu algum erro ao processar sua confirmação.</p>
                </>
                : null}
            </div>
        

            </div>
        </div>

        <Footer />
        </>
    )
};