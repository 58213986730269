import './satisfeitos.css'
import {FiUsers, FiClock} from 'react-icons/fi'
import {GiShop} from 'react-icons/gi'
import {RiUserFollowLine} from 'react-icons/ri'

import { useEffect } from 'react'

export default function Satisfeitos(){

    function ShowSatisfeitos(){

        Array.from(document.getElementsByClassName('satisfeitos-body')).forEach((item, index) => {
        
            if(document.getElementById('satisfeitos').getBoundingClientRect().top < window.innerHeight){
                
                item.classList.add('show')
            }
        
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", ShowSatisfeitos);
        return () => window.removeEventListener("scroll", ShowSatisfeitos)
    }, [])

    return (
        <>
        <div id="satisfeitos" className="satisfeitos">
            <div className="satisfeitos-glass">

                <div className="satisfeitos-body">
                    <div>
                    <p className="numero"><FiUsers /> +400</p>
                    <p>Clientes Satisfeitos</p>
                    </div>

                    <div>
                    <p className="numero"><FiClock /> +10</p>
                    <p>Anos de Experiência</p>
                    </div>

                    <div>
                    <p className="numero"><GiShop /> +10</p>
                    <p>Shoppings Atendidos</p>
                    </div>

                    <div>
                    <p className="numero"><RiUserFollowLine /> +2500</p>
                    <p>Seguidores</p>
                    </div>

                </div>

            </div>            
            
        </div>
        </>
    )
}