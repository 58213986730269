import './contato.css'

import { FiPhone } from 'react-icons/fi'
import { SiWhatsapp } from 'react-icons/si'
import { GrSend, GrMap } from 'react-icons/gr'
import norteContab from '../../assets/Norte-Contabilidade.png'
import sempreVisto from '../../assets/sempre-visto.png'


export default function Contato(){

    return (
        <div id="section-contato" className="section-contato">

            <div className="contato-item">
                <h3>Quer entrar em contato?</h3>

                <div className="contato-item-content">

                    <div className="contato-item-label-group">
                        <label><FiPhone /></label>
                    
                        <p>(21) 3012-5915 / (21) 4063-3737 </p>
                    </div>

                    <div className="contato-item-label-group">
                        <label><SiWhatsapp /></label>
                    
                        <p>(21) 9 6945-2915 / (21) 9 9764-8984</p>
                    </div>

                    <div className="contato-item-label-group">
                        <label><GrSend /></label>
                    
                        <p>comercial@rlconect.com.br</p>
                    </div>

                    
                    <div className="contato-item-label-group">
                        <label><GrMap /></label>

                        <p>Av. Dom Helder Câmara, 5644</p>
                    </div>

                </div>
                
            </div>


            <div className="contato-item">
                <h3>Parceiros</h3>

                <div className="contato-item-label-group-parceiros">
                        
                    <img src={norteContab} alt="norte-contabilidade"/>
                    
                    <img src={sempreVisto} alt="sempre-visto"/>
                    
                </div>

            </div>


        </div>
    )
}