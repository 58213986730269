import { useState, createContext, useEffect } from 'react'

import { gql, useMutation, useLazyQuery } from '@apollo/client'

export const AuthContext = createContext({});

const VERIFY_TOKEN = gql`
    mutation verificar(
        $token: String!,
    ){
        verifyToken(
            token: $token,
        ){
            success,
            errors,

        }
    }
`

const ME_QUERY = gql`
    query me{
        me{
            username
        }
    }
`

function AuthProvider({ children }){
    
    const [user, setUser] = useState(null);
    
    const [verificarToken] = useMutation(VERIFY_TOKEN)

    const [meQuery] = useLazyQuery(ME_QUERY)

    useEffect(() => {

        function loadStorage(){
            const storageToken = localStorage.getItem('JWT');
            const storageUser = localStorage.getItem('user')

            if(storageToken){
                verificarToken({variables: {token: storageToken}})
            .then(response => {
                if(response.data.verifyToken.success === false){
                    localStorage.removeItem('JWT')
                    localStorage.removeItem('user')
                }
            })
            }
            if(storageUser){
                setUser(storageUser)
            }
        }

        loadStorage();
    }, [verificarToken, meQuery])


    function LogOut(){
        localStorage.removeItem('user');
        localStorage.removeItem('JWT');
        setUser(null)
    }

    return(
        <AuthContext.Provider value={ {logado: !!user, user, setUser, LogOut }}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthProvider