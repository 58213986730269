import './footer.css'

export default function Footer(){

    return (
        <div className="footer">

            <div className="footer-item">
                <p>© | 2021 R&L Connect Telecomunicações</p>
            </div>
            
        </div>
    )
}